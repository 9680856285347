import React, { Component } from "react"
import styled from "styled-components"
import { styles } from "../../utils"
import { FaYoutube, FaTwitter } from "react-icons/fa"

export default class Footer extends Component {
  state = {
    icons: [
      {
        id: 0,
        icon: <FaTwitter className="icon twitter-icon" />,
        path: "https://www.twitter.com/eddynardo11",
      },
      {
        id: 1,
        icon: <FaYoutube className="icon youtube-icon" />,
        path: "https://www.youtube.com/channel/UCsXJ9GxAlNICSdGoqlVsjHQ",
      },
    ],
  }

  render() {
    return (
      <FooterWrapper>
        {/* <div className="title">eddynardo</div> */}
        <div className="icons">
          {this.state.icons.map(item => (
            <a
              href={item.path}
              key={item.id}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.icon}
            </a>
          ))}
        </div>
        <p className="copyright"> &copy; eddynardo 2022</p>
      </FooterWrapper>
    )
  }
}

const FooterWrapper = styled.footer`
  padding: 2rem 0;
  background: ${styles.colors.mainBlue};
  .icons {
    width: 5rem;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
  }
  .icon {
    color: ${styles.colors.mainWhite};
    font-size: 1.3rem;
    ${styles.transFunction()};
    &:hover {
      color: ${styles.colors.subPink};
    }
  }

  .copyright {
    color: ${styles.colors.mainWhite};
    //text-transform:capitalize;
    text-align: center;
    margin: 1rem 0;
    letter-spacing: 0.1rem;
  }
  .title {
    text-align: center;
    width: 10rem;
    color: ${styles.colors.mainWhite};
    //text-transform:uppercase;
    padding: 0.3rem 1rem;
    margin: 0 auto 2rem auto;
    font-size: 1.5rem;
    ${styles.border({ color: `${styles.colors.mainWhite}` })};
  }
`
